import {
  ExperimentNames,
  MAX_PRICE,
  getCurrencySymbol,
  isCurrencySuffixed,
  minimumDonation,
  normalizePrice,
} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEnvironment, useExperiments, useTranslation} from '@wix/yoshi-flow-editor'
import React, {useEffect, useRef, useState} from 'react'
import {TextField} from 'wix-ui-tpa/cssVars'
import {useCurrencyFormatter} from '../../../../../commons/hooks/currency'
import {DONATION_ERROR} from '../../constants/donation-error'
import s from './donation-input.scss'
import {DonationInputProps} from './interfaces'

export const DonationInput = ({
  ticket,
  onChange,
  onBlur,
  value,
  error,
  label,
  className,
  ignoreMinimum,
}: DonationInputProps) => {
  const {isMobile} = useEnvironment()
  const {t} = useTranslation()
  const [localValue, setLocalValue] = useState(value ?? '')
  const {experiments} = useExperiments()

  const currency = ticket.price.currency
  const suffixedCurrency = isCurrencySuffixed(currency)
  const symbol = getCurrencySymbol(currency)
  const minimum = ignoreMinimum ? 0 : minimumDonation(ticket)
  const {getFormattedMoney} = useCurrencyFormatter()

  const inputRef = useRef<HTMLInputElement>(null)

  const newErrorMessagesEnabled = experiments.enabled(ExperimentNames.NewErrorMessages)

  useEffect(() => {
    if (value === undefined) {
      setLocalValue(value?.toString() ?? '')
    }
  }, [value])

  const handleChange = (val: string) => {
    if (val.length === 0) {
      onChange(undefined)
      setLocalValue('')
    } else {
      const normalizedValue = normalizePrice(val, localValue?.toString(), {max: MAX_PRICE})
      onChange(normalizedValue !== undefined ? normalizedValue : undefined)
      setLocalValue(normalizedValue !== undefined ? normalizedValue : '')
    }
  }

  const getError = () => {
    const ERRORS = {
      [DONATION_ERROR.EMPTY_DONATION]: () => t('tickets.donationError.empty'),
      [DONATION_ERROR.MINIMUM_NOT_REACHED]: () =>
        t('tickets.donationError.minimum', {
          price: getFormattedMoney({amount: minimum?.toString(), currency}),
        }),
    }
    return ERRORS[error]()
  }

  const handleBlur = () => {
    if (value && value.endsWith('.')) {
      handleChange(value.slice(0, value.length - 1))
    }
    onBlur()
  }

  const handleFocusHack = () => {
    // HACK: briefly setting opacity to 0 forces iOS not to scroll when opening keyboard for input
    // and so doesn't shift all clickable elements
    // More about this: https://gist.github.com/kiding/72721a0553fa93198ae2bb6eefaa3299
    if (isMobile && inputRef.current) {
      inputRef.current.style.opacity = '0'
      setTimeout(() => (inputRef.current.style.opacity = '1'), 100)
    }
  }

  return (
    <div onClick={event => event.stopPropagation()} className={s.donationInputContainer}>
      <TextField
        data-hook={DH.DONATION_INPUT}
        inputMode="decimal"
        pattern={'[0-9]+(\\.[0-9]{1,2})?'}
        value={localValue ?? ''}
        newErrorMessage
        // @ts-expect-error
        errorAppearance={newErrorMessagesEnabled ? 'BackgroundAndBorder' : 'TextOnly'}
        inputRef={_input => {
          inputRef.current = _input
        }}
        className={className}
        label={
          label ?? minimum
            ? t('tickets.donationInputLabelWithMinimum', {
                price: getFormattedMoney({amount: minimum.toString(), currency}),
              })
            : t('tickets.donationInputLabel')
        }
        error={Boolean(error)}
        errorMessage={error ? getError() : undefined}
        onChange={e => handleChange(e.target.value)}
        onFocus={handleFocusHack}
        onBlur={handleBlur}
        prefix={suffixedCurrency ? undefined : symbol}
        suffix={suffixedCurrency ? symbol : undefined}
      />
    </div>
  )
}
