import {GetFormattedMoney} from '@wix/wix-events-commons-statics'
import {
  ControllerFlowAPI,
  IWixAPI,
  useCurrencyFormatter as useInfraCurrencyFormatter,
  useTranslation,
} from '@wix/yoshi-flow-editor'
import {useAppSelector} from '../../components/details-page/Widget/hooks/runtime'
import {getCountry, getLocale} from '../selectors/environment'
import {getLocale as getLocaleFromWixCode} from '../utils/wix-code-api'

export const getWorkerCurrencyFormatter = (flowApi: ControllerFlowAPI, wixCodeApi: IWixAPI) => {
  const {t} = flowApi.translations
  const regionalSettings = getLocaleFromWixCode(wixCodeApi)
  const formatCurrency = flowApi.getCurrencyFormatter({language: regionalSettings})

  return getCurrencyFormatter({formatCurrency, t})
}

const getCurrencyFormatter = ({t, formatCurrency}) => {
  return {
    getFormattedMoney: (price: wix.events.Money): string => {
      if (!price.currency) {
        return price.amount
      }
      return formatCurrency({value: price.amount, currency: price.currency})
    },
    getTicketPriceText: (price: wix.events.Money): string => {
      const amount = Number(price.amount)

      if (!amount) {
        return t('ticketPrice.free')
      }

      if (!price.currency) {
        return amount.toString()
      }

      return formatCurrency({value: price.amount, currency: price.currency})
    },
  }
}

export const useCurrencyFormatter = () => {
  const country = useAppSelector(getCountry)
  const language = useAppSelector(getLocale)
  const formatCurrency = useInfraCurrencyFormatter({language, country})
  const {t} = useTranslation()

  return getCurrencyFormatter({t, formatCurrency})
}

export type GetTicketPriceText = (price: wix.events.Money) => string

export type PriceFormatters = {getTicketPriceText: GetTicketPriceText; getFormattedMoney: GetFormattedMoney}
