import {TicketWithFormattedSalePeriod} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import React, {PropsWithChildren} from 'react'
import {a11yLabelId, a11yTicketId, a11yTicketTypeId} from '../a11y'
import s from './ticket-container.scss'

export const TicketContainer = ({
  children,
  innerContainerClassName,
  ticket,
}: PropsWithChildren<{innerContainerClassName?: string; ticket: TicketWithFormattedSalePeriod}>) => {
  const ariaTicketId = a11yTicketId(ticket)
  const ariaTicketTypeId = a11yTicketTypeId(ticket)
  const ariaLabeledBy = `${a11yLabelId(ariaTicketTypeId)} ${ariaTicketTypeId}`

  return (
    <li className={s.container} data-hook={DH.TICKET} id={ariaTicketId}>
      {/* aria labels don't work on <li> */}
      <div role="listitem" className={innerContainerClassName} aria-labelledby={ariaLabeledBy}>
        {children}
      </div>
    </li>
  )
}
